export default [
  // {
  //   title: '示例',
  //   icon: 'HomeIcon',
  //   countTag: true,
  //   tagVariant: 'light-success',
  //   children: [
  //     {
  //       title: '按钮',
  //       route: 'example-button',
  //       icon: 'TrendingUpIcon',
  //       resource: 'dashboard',
  //     },
  //     {
  //       title: '图片裁剪',
  //       route: 'example-cropper',
  //       icon: 'TrendingUpIcon',
  //       resource: 'dashboard',
  //     },
  //     {
  //       title: 'Bullet',
  //       route: 'example-bullet',
  //       icon: 'TrendingUpIcon',
  //       resource: 'dashboard',
  //     },
  //     {
  //       title: 'Ribbon',
  //       route: 'example-ribbon',
  //       icon: 'TrendingUpIcon',
  //       resource: 'dashboard',
  //     },
  //     {
  //       title: 'Symbol',
  //       route: 'example-symbol',
  //       icon: 'TrendingUpIcon',
  //       resource: 'dashboard',
  //     },
  //   ],
  // },
]
