export default [
  {
    title: '首页',
    icon: 'HomeIcon',
    countTag: true,
    tagVariant: 'light-warning',
    children: [
      {
        title: '工作台',
        route: 'dashboard-workbench',
        icon: 'AirplayIcon',
        authz: false,
      },
      {
        title: '仪表盘',
        route: 'dashboard-home',
        icon: 'TrendingUpIcon',
        resource: 'dashboard',
      },
    ],
  },
]
