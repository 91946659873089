/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
import equipmenteCenter from './device-center'
import dataCenter from './data-center'
import ops from './ops'
import openApi from './open-api'
import system from './system'
import example from './example'

// Array of sections
export default [...dashboard, ...equipmenteCenter, ...dataCenter, ...ops, ...openApi, ...system, ...example]
