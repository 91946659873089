<template>
  <b-nav-item @click="$router.push({ name: 'dashboard-workbench' })">
    <b-avatar
      v-tooltip.hover="'工作台'"
      v-ripple.400="$x.ripple.primary"
      variant="light-primary">
      <feather-icon size="18" class="x-link" icon="AirplayIcon"/>
    </b-avatar>
  </b-nav-item>
</template>

<script>
import { BNavItem, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BAvatar,
  },
  setup() {
    return {}
  },
}
</script>
