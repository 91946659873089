<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <b-avatar
        v-if="notifyCount > 0 || alarmCount > 0"
        badge
        v-tooltip.hover="'消息'"
        v-ripple.400="$x.ripple.warning"
        variant="light-primary"
        class="badge-minimal animate__animated animate__infinite animate__swing "
        badge-variant="danger"
        badge-offset="-0.5em"
        badge-top>
        <!--<feather-icon icon="BellIcon" size="18" class="text-warning"/>-->
        <span class="el-icon-message-solid font-medium-3 x-link"></span>
      </b-avatar>
      <b-avatar
        v-else
        v-tooltip.hover="'消息'"
        v-ripple.400="$x.ripple.warning"
        variant="light-primary">
        <feather-icon icon="BellIcon" size="18"/>
        <!--<span class="el-icon-message-solid font-medium-3"></span>-->
      </b-avatar>
    </template>
    <b-tabs align="center" class="mt-1">
      <b-tab title="未读消息" lazy>
        <template #title>
          <div class="pl-50 pr-50 pt-50 pb-1 text-primary">
            未读消息（
            <div class="d-inline text-danger x-link">{{  notifyCount }}</div>
            条）
          </div>
        </template>
        <div class="alarm-content d-flex flex-column justify-content-center align-items-center">
          <x-table
            ref="notifyTable"
            :card="false"
            :fixed="false"
            no-page
            class="x-w-100"
            :show-actions="false"
            :options="notifyOptions"
            @search="notifySearchHandle"
          >
            <template slot="id" slot-scope="{row}">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex flex-column">
                  <span class="font-medium-1 x-text-bold text-body">{{row.topicName}}</span>
                  <span class="text-muted">{{row.message}}</span>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-muted">{{ row.notifyTime  | timeAgo }}</span>
                  <span class="x-mt-5px x-hover badge badge-light-primary" @click="readNotify(row.id)">标为已读</span>
                </div>
              </div>
            </template>
          </x-table>
          <b-button
            :to="{ name: 'system-usercenter-notifyrecords'}"
            v-ripple.400="$x.ripple.primary"
            variant="primary"
            class="mb-2 x-w-80"
          >更多</b-button>
        </div>
      </b-tab>
      <b-tab title="告警" lazy>
        <template #title>
          <div class="pl-50 pr-50 pt-50 pb-1 text-primary">
            告警（
            <div class="d-inline text-danger x-link">{{  alarmCount }}</div>
            条）
          </div>
        </template>
        <div class="alarm-content d-flex flex-column justify-content-center align-items-center">
          <x-table
            ref="alarmTable"
            :card="false"
            :fixed="false"
            no-page
            class="x-w-100"
            :show-actions="false"
            :options="alarmOptions"
            @search="alarmSearchHandle"
          >
            <template slot="alarmName" slot-scope="{data}">
              <span class="badge badge-light-danger">{{data}}</span>
            </template>
            <template slot="alarmTime" slot-scope="{row}">
              <div>
                <span class="text-muted">{{row.alarmTime  | timeAgo}}</span>
              </div>
            </template>
          </x-table>
          <b-button
            @click="toSolve"
            v-ripple.400="$x.ripple.primary"
            variant="primary"
            class="mb-2 x-w-80"
          >前往处理</b-button>
        </div>
      </b-tab>
    </b-tabs>
  </b-nav-item-dropdown>
</template>

<script>
import { getDeviceAlarmRecord, countByState as alarmCountByState } from '@/api/ops/alarm-record'
import { query as queryNotificationRecords, read as readNotifyById } from '@/api/system/usercenter/notification-record'
import {
  BNavItemDropdown, BButton, BTabs, BTab, BAvatar,
} from 'bootstrap-vue'
import XTable from '@core/components/cx/table/XTable.vue'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BNavItemDropdown,
    XTable,
    // BLink,
    // BAvatar,
    // VuePerfectScrollbar,
    BButton,
    BTabs,
    BTab,
    BAvatar,
  },
  created() {
    queryNotificationRecords(0, 1, {
      state: 'unread',
    }).then(resp => {
      this.notifyCount = resp.data.result.total
    })
    alarmCountByState('newer').then(resp => {
      this.alarmCount = resp.data.result
    })
  },
  data() {
    return {
      notifyCount: 0,
      alarmCount: 0,
      notifyOptions: {
        actions: [],
        columns: [{
          label: '',
          prop: 'id',
          searchShow: false,
        }],
      },
      alarmOptions: {
        actions: [],
        columns: [{
          label: '设备名称',
          labelShow: true,
          prop: 'deviceName',
          linkUrl: '/device-center/device/view/{deviceId}',
          searchShow: false,
        }, {
          label: '告警名称',
          labelShow: true,
          prop: 'alarmName',
          rowShow: true,
          searchShow: false,
        }, {
          label: '告警时间',
          labelShow: true,
          sortable: false,
          prop: 'alarmTime',
          addShow: false,
          editShow: false,
          searchShow: false,
        },
        ],
      },
    }
  },
  methods: {
    notifySearchHandle(page, params, done) {
      params.state = 'unread'
      queryNotificationRecords(page.pageIndex - 1, 6, params).then(resp => {
        this.notifyCount = resp.data.result.total
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    readNotify(id) {
      readNotifyById(id).then(() => {
        this.$refs.notifyTable.__searchHandle()
      })
    },
    alarmSearchHandle(page, params, done) {
      params.state = 'newer'
      getDeviceAlarmRecord(page.pageIndex - 1, 6, params).then(resp => {
        this.alarmCount = resp.data.result.total
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    toSolve() {
      this.$router.push({ name: 'ops-alarm' })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>
.header-navbar .navbar-container .dropdown-menu-media {
   width: 42rem !important;
}
.alarm-content {
  margin-top: -2.0rem;
}
</style>
