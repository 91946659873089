export default [
  {
    header: '开放平台',
  },
  {
    title: '第三方平台',
    icon: 'CodepenIcon',
    route: 'open-open-api',
    resource: 'open-api',
  },
]
